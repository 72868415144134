import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';

export const Panel = styled.div`
  background-color: ${prop('theme.colors.white')};
  border: 1px solid ${prop('theme.colors.inputGrey')};
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  max-width: 856px;
  padding: 20px 42px 0;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: column;
    padding: 20px 20px 0;
  }
`;

export const PanelItem = styled.div`
  margin-bottom: 20px;
  max-width: ${prop('$width')}%;
  padding-right: 16px;
  width: 100%;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    padding-right: 0;
    max-width: initial;
  }
`;

export const PanelLabel = styled.p`
  font-size: 0.875rem;
  font-weight: ${prop('theme.fontWeights.medium')};
  line-height: 1rem;
  margin-bottom: 8px;
`;

export const PanelValue = styled.p`
  font-size: 0.875rem;
  font-weight: ${prop('theme.fontWeights.light')};
  line-height: 1rem;
  margin-bottom: 0;

  ${ifProp(
    { $format: 'capitalize' },
    css`
      text-transform: capitalize;
    `
  )}
`;
