import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';

import SpinnerContainer from '../../../SpinnerContainer';
import SponsorPanel from './Sponsorship/SponsorPanel';
import MemberPanel from './Membership/MemberPanel';

const SPONSORSHIP = 'sponsorship';
const MEMBERSHIP = 'membership';

const PanelBlockContainer = styled.div`
  margin-bottom: 36px;
`;

const BlockTitle = styled.h4`
  color: ${prop('theme.colors.black')};
  font-size: 1rem;
  font-weight: ${prop('theme.fontWeights.medium')};
  letter-spacing: 0.06em;
  line-height: 1rem;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const propTypes = {
  data: PropTypes.shape({
    items: PropTypes.array,
    primary: PropTypes.object,
    slice_type: PropTypes.string
  })
};

const defaultProps = {
  data: {}
};

function ContentPanelBlock({ data }) {
  const { sponsorship } = useSelector((state) => get(state, 'memberInfo'));
  const { subscriptions } = useSelector((state) =>
    get(state, 'memberInfo.subscriber')
  );

  if (!data) {
    return null;
  }

  const validSubscriptions =
    subscriptions?.length === 1
      ? subscriptions
      : subscriptions?.filter((subscription) => {
          // If a subscription is marked as 'cancelled' but does not have an external_subscription_code, then it's a duplicate record we manually cancelled
          // We do not ever want to display these extra records, as they mostly still exist so that we have a record of the error behind the scenes
          // The user should not know about these records, so we simply do not display them
          return (
            subscription.status !== 'cancelled' ||
            subscription.external_subscription_code
          );
        });

  const dataMap = {
    [SPONSORSHIP]: sponsorship,
    [MEMBERSHIP]: validSubscriptions
  };

  const blockTitle = get(data, 'primary.block_title');
  const panelType = get(data, 'primary.panel_type');
  const panelItems = get(data, 'items', []);
  const panelData = dataMap[panelType];

  return (
    <>
      {panelData && (
        <PanelBlockContainer>
          {blockTitle.richText && <BlockTitle>{blockTitle.text}</BlockTitle>}
          {panelType === MEMBERSHIP ? (
            <>
              {subscriptions?.length === 0 ? (
                <SpinnerContainer>
                  <Spinner style={{ width: '64px', height: '64px' }} />
                </SpinnerContainer>
              ) : (
                <>
                  {panelData?.length > 0 &&
                    panelData.map((panelSubscription, index) => (
                      <MemberPanel
                        key={index}
                        panelType='membership'
                        panelData={panelData}
                        panelItems={panelItems}
                        subscription={panelSubscription}
                      />
                    ))}
                </>
              )}
            </>
          ) : (
            <SponsorPanel
              panelData={panelData}
              panelItems={panelItems}
              panelType='sponsorship'
            />
          )}
        </PanelBlockContainer>
      )}
    </>
  );
}

ContentPanelBlock.propTypes = propTypes;
ContentPanelBlock.defaultProps = defaultProps;

export default ContentPanelBlock;
