import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';

import { Panel, PanelItem, PanelLabel, PanelValue } from '../PanelStyles';

const propTypes = {
  panelData: PropTypes.object,
  panelItems: PropTypes.arrayOf(
    PropTypes.shape({
      content_width: PropTypes.number,
      panel_label: PropTypes.string,
      panel_value: PropTypes.string
    })
  )
};

const defaultProps = {
  panelData: {},
  panelItems: []
};

function SponsorPanel({ panelData, panelItems }) {
  if (!panelData) {
    return null;
  }

  return (
    <Panel>
      {panelItems.map((item, index) => {
        const panelLabel = get(item, 'panel_label');
        const panelValue = get(item, 'panel_value');

        return (
          <PanelItem key={index} $width={item.content_width}>
            {panelLabel && <PanelLabel>{panelLabel}</PanelLabel>}
            {panelValue && <PanelValue>{panelData[panelValue]}</PanelValue>}
          </PanelItem>
        );
      })}
    </Panel>
  );
}

SponsorPanel.propTypes = propTypes;
SponsorPanel.defaultProps = defaultProps;

export default SponsorPanel;
