import React from 'react';
import PropTypes from 'prop-types';

import { PanelValue } from '../PanelStyles';

const propTypes = {
  activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cancelledValue: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  format: PropTypes.string,
  expiredValue: PropTypes.string,
  pendingValue: PropTypes.string,
  status: PropTypes.string
};

const defaultProps = {
  activeValue: '',
  cancelledValue: '',
  format: '',
  expiredValue: '',
  pendingValue: '',
  status: ''
};

function MemberPanelValue({
  activeValue,
  cancelledValue,
  format,
  expiredValue,
  pendingValue,
  status
}) {
  const values = {
    active: activeValue,
    pending: pendingValue,
    expired: expiredValue,
    cancelled: cancelledValue
  };

  return <PanelValue $format={format}>{values[status]}</PanelValue>;
}

MemberPanelValue.propTypes = propTypes;
MemberPanelValue.defaultProps = defaultProps;

export default MemberPanelValue;
