import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';

import MemberPanelValue from './MemberPanelValue';
import { Panel, PanelItem, PanelLabel } from '../PanelStyles';
import { AlertContainer } from '../../../Registration/Alert';
import redExclamation from '../../../../../images/icons/red-exclamation-point.svg';
import greenCheckmark from '../../../../../images/icons/green-check-outlined-circle.svg';
import dayjs from '../../../../../utils/dayjs';
import { CurrentStatus } from '../../../shared-styled-components';

const AlertWrapper = styled(AlertContainer)`
  font-weight: ${prop('theme.fontWeights.light')};
  font-size: 1rem;
  margin-bottom: 16px;
  max-width: 856px;

  img {
    margin-right: 30px;
  }
`;

const propTypes = {
  panelItems: PropTypes.arrayOf(
    PropTypes.shape({
      content_width: PropTypes.number,
      panel_label: PropTypes.string,
      panel_value: PropTypes.string
    })
  ),
  subscription: PropTypes.object
};

const defaultProps = {
  panelItems: [],
  subscription: {}
};

function MemberPanel({ panelItems, subscription }) {
  const status = get(subscription, 'status');
  const formatDate = (date) => {
    return dayjs(date).format('MMMM DD, YYYY');
  };

  return (
    <>
      {status === 'pending' && (
        <AlertWrapper $borderColor='redPrimary'>
          <img src={redExclamation} />
          Your Community Membership is on hold pending Sponsorship Verification
          or Payment Via Credit Card.
        </AlertWrapper>
      )}
      <Panel>
        {panelItems.map((item, index) => {
          const panelLabel = get(item, 'panel_label');
          const panelValue = get(item, 'panel_value');

          return (
            <PanelItem key={index} $width={item.content_width}>
              {panelLabel && <PanelLabel>{panelLabel}</PanelLabel>}
              {panelValue === 'status' && (
                <MemberPanelValue
                  activeValue={
                    <CurrentStatus $status={status}>
                      <img src={greenCheckmark} />
                      {subscription[panelValue]}
                    </CurrentStatus>
                  }
                  cancelledValue={
                    <CurrentStatus $status={status}>
                      <img src={redExclamation} />
                      {subscription[panelValue]}
                    </CurrentStatus>
                  }
                  format='capitalize'
                  expiredValue={subscription[panelValue]}
                  pendingValue={subscription[panelValue]}
                  status={status}
                />
              )}
              {panelValue === 'start_date' && (
                <MemberPanelValue
                  activeValue={formatDate(subscription[panelValue])}
                  cancelledValue={formatDate(subscription[panelValue])}
                  expiredValue={formatDate(subscription[panelValue])}
                  pendingValue='Start date is unassigned'
                  status={status}
                />
              )}
              {panelValue === 'end_date' && (
                <MemberPanelValue
                  activeValue='Lifetime'
                  cancelledValue='End date is unassigned'
                  expiredValue='End date is unassigned'
                  pendingValue='End date is unassigned'
                  status={status}
                />
              )}
              {panelValue === 'external_subscription_code' && (
                <MemberPanelValue
                  activeValue={subscription[panelValue]}
                  cancelledValue={subscription[panelValue]}
                  expiredValue={subscription[panelValue]}
                  pendingValue='Not assigned'
                  status={status}
                />
              )}
            </PanelItem>
          );
        })}
      </Panel>
    </>
  );
}

MemberPanel.propTypes = propTypes;
MemberPanel.defaultProps = defaultProps;

export default MemberPanel;
